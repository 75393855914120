body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

em {
  background-color: yellow; /* Highlight background color */
  color: black; /* Text color */
  font-weight: bold; /* Make the text bold for emphasis */
  padding: 2px; /* Add some padding for better visibility */
  border-radius: 3px; /* Optional: add rounded corners */
}