#chat_info {
    background-color: #f9f9f9;
    border: 2px solid #4CAF50;
    padding: 10px 15px 0px 15px;
    border-radius: 5px;
}
#chat_info_title {
    color: #4CAF50;
    font-size: 1em;
    margin-bottom: -15px;
    font-weight: bold;
}
#chat_info_message p {
    font-size: 0.8em;
    color: #333;
}