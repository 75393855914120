@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.location_icon:before {
  content: url(../assets/images/location_icon.svg);
  white-space: nowrap;
}

.experience_icon:before {
  content: url(../assets/images/experience_icon.svg);
  white-space: nowrap;
}

.job_icon:before {
  content: url(../assets/images/job_icon.svg);
  white-space: nowrap;
}

.skill_icon:before {
  content: url(../assets/images/job_icon.svg);
  white-space: nowrap;
}

.full_width.container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.body {
  min-height: 100vh;
  font-family: sans-serif;
  margin: 0;
}

.scroll_to_top {
  z-index: 100;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll_to_bottom {
  z-index: 100;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  top: 4em;
  color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

.ro-ai-trainer {
  z-index: 10; 
  position: fixed; 
  width: 100%;
  height: 50px;
  right: 0px;
  bottom: 0px;
  background-color: white;
}

.appChatbotContainer {
  border-radius: 5px;
  bottom: 70px;
  box-shadow: 5px 5px 13px rgba(91,81,81,.4);
  display: flex;
  justify-content: center;
  margin: 40px 0;
  position: fixed;
  right: 10px;
  z-index: 6;
}

.appChatbotButton {
  background-color: #417fc2;
  border: none;
  border-radius: 25px;
  bottom: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  width: 50px;
  z-index: 6;
}

.appChatbotButtonIcon {
  fill: #fff;
  height: 25px;
  width: 25px;
}

/* Container styling */
.signin-container {
  width: 300px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* General input styling */
.signin-container input[type="text"],
.signin-container input[type="email"],
.signin-container input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Remove border from the SelectPicker toggle */
.rs-picker-toggle {
  border: none !important;
  font-size: 16px;
}

.rs-picker-select-menu-item {
  font-size: 16px; /* Change this value to your desired font size */
}

/* Optional: Remove border when active */
.rs-picker-toggle.rs-picker-toggle-active {
  border: none !important;
}

/* Optional: Remove border when disabled */
.rs-picker-toggle.rs-picker-toggle-disabled {
  border: none !important;
}


.signin-select-container {
  width: 100%;
  padding: 12px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Focus effects */
.signin-container input[type="text"]:focus,
.signin-container input[type="password"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Button styling */
.signin-container Button[type="submit"] {
  width: 100%;
  background-color: #417fc2;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.signin-container input[type="submit"]:hover {
  background-color: #0056b3;
}

/* Label styling */
.signin-container label {
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
  color: #333;
}

.center-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  overflow-y: auto;
}

table.candidate_tab {
  width: 100%;
  border-collapse: collapse;
}

td.candidate_tab_field_title {
  width: 100px; /* Fixed width for the field title */
  font-weight: bold; /* Optional: Make the field title bold */
  vertical-align: top; /* Aligns text at the top */
  padding-right: 10px; /* Space between the title and the value */
}

td.candidate_tab_field_value {
  word-wrap: break-word; /* Allows text to wrap within the container */
}

.more_less_toggle_button {
  background: none;
  border: none;
  color: #007BFF; /* A nice blue color that stands out on white */
  cursor: pointer;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
}

.more_less_toggle_button:hover {
  color: #0056b3; /* Darker blue for hover effect */
}

.verification-container {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}
.verification-container input {
  width: 40px;
  height: 60px;
  text-align: center;
  font-size: 32px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.google-icon {
  content: url(../assets/images/web_light_sq_na.svg);
}
.microsoft-icon {
  content: url(../assets/images/ms-symbollockup_mssymbol_19.svg);
}
.dns-mx-record-icon {
  content: url(../assets/images/dns-mx-record-logo.png);
  border-radius: 5px;
  border: 0px solid #999999;
}

.google-sign-in-button-image {
  content: url(../assets/images/web_light_sq_SI.svg);
}

.no-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.product-pricing-border {
  margin: 10px;
  border-radius: 10px;
  border: 2px solid #007BFF;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.blue-divider {
  border-top: 2px solid  #007BFF;
  margin: 20px 0
}

/* Example usage: <div className="ribbon ribbon-top-right"><span>20% Off with<br/>Promo Code TALENT20</span></div> */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #f0c14b; /* Yellow color */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 5px 0;
  background-color: #fff820; /* Yellow color */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

@keyframes jiggle {
  0%, 100% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
}

.jiggle {
  display: inline-block;
  animation: jiggle 0.3s infinite;
}

/* Ensure the sidenav takes full height and scrolls if content overflows */
.sidenav-container {
  height: 100vh; /* Full height of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .sidenav-container {
    width: 60px; /* Smaller width on mobile devices */
  }

  .rs-sidenav-body {
    padding-left: 10px; /* Adjust padding if necessary */
  }

  .rs-nav-item-content {
    display: none; /* Hide text labels on mobile */
  }

  .rs-nav-item-icon {
    margin-right: 0; /* Center icons */
  }
}


