@import '~rsuite/dist/rsuite.min.css';

/* Dashboard */
.card {
  background: #fff;
  margin-top: 10px;
  border-radius: 6px;
}
.card h3 {
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 18px;
}

/* Job Table */
.rs-drawer-footer {
  text-align: left;
}
.rs-table {
  background: #fff;
}
.rs-table .link-group {
  cursor: pointer;
}
.rs-table .link-group .rs-table-cell-content {
  padding: 5px;
}

/* Job Wizard */
.wizard-form {
  max-width: 1000px;
  margin: 0 auto;
}

.rs-radio-tile {
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #e5e5ea;
  padding: 10px;
  position: relative;
  cursor: pointer;
}
.rs-radio-tile:hover, .rs-radio-tile.checked {
  border: 2px solid #3498ff;
  background: aliceblue;
}
.rs-radio-tile-title {
  font-weight: bold;
}
.rs-radio-tile .rs-radio-tile-check {
  background: #3498ff;
  border-bottom-left-radius: 50%;
  height: 48px;
  position: absolute;
  right: -24px;
  top: -24px;
  width: 48px;
  z-index: 3;
  opacity: 0;
}
.rs-radio-tile .rs-radio-tile-check .rs-icon {
  position: absolute;
  font-size: 16px;
  top: 25px;
  left: 7px;
  color: #fff;
}
.rs-radio-tile.checked .rs-radio-tile-check {
  opacity: 1;
}
.rs-radio-tile input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}
.rs-radio-tile .rs-icon {
  font-size: 32px;
}

/* Page headers */
.header-panel {
  height: 100px;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
}

.body-panel {
  margin-top: 10px;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
}

/* Timeline */
.custom-timeline {
  margin-left: 20px;
}
.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  position: absolute;
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 18px;
  color: #999;
  margin-left: -4px;
  justify-content: center;
  padding: 10px;
}
.custom-timeline .rs-timeline-item-content {
  margin-left: 24px;
}
.rs-timeline-item-content p {
  margin: 0;
}

.calendar-candidate-list {
  text-align: left;
  padding: 0;
  list-style: none;
}

.calendar-candidate-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}

.calendar-candidate-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}  

/* Workspace, left side navbar, header, and workspace */

/* Page defaults */
.ro-page-default {
  background-color: #f2f2f4;
}

/* Header */
.ro-header {
  z-index: 10; 
  position: fixed; 
  width: 100%;
}

/* Body includes side navbar and workspace */
.ro-body {
  background-color: #f2f2f4;
}

/* Bottom is currently undefined with no content */
.ro-bottom {
  background-color: #f2f2f4;
  padding: 0px;
  margin: 0px;
}

/* Left side navbar */
.ro-side-navbar {
  position: fixed;
  margin-left: -12px;
  padding: 0px; 
  display: flex;
  min-height: 100vh;
  z-index: 8;
  overflow-y: scroll;
  top: 69px;
  bottom: 0;
}

/* Outlet or workspace */
.ro-workspace {
  margin: 0px;
  padding-top: 80px;
  margin-right: 0px;
  padding-right: 0px;
}

.centered-modal .rs-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-modal .rs-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
}

