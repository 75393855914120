 /* https://divtable.com/table-styler/ */
.markdown {
    a {
        color: #1c60cd; /* Blue color */
        font-weight: bold; /* Bold font weight */
        text-decoration: none; /* Remove default underline */
    }
      
    a:hover {
        color: #1c60cd;
        text-decoration: underline; /* Underline on hover */
    }

    table {
        border: 2px solid #4F7849;
        background-color: #EEEEEE;
        text-align: center;
        border-collapse: collapse;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      table td, table th {
        border: 1px solid #4F7849;
        padding: 3px 2px;
        padding: 10px;
      }
      table tbody td {
        font-size: 13px;
        font-weight: bold;
        color: #41633C;
        padding: 10px;
        text-align: left
      }
      table tr:nth-child(even) {
        background: #CEE0CC;
      }
      table thead {
        background: #4F7849;
        background: -moz-linear-gradient(top, #8dc085 0%, #60855b 66%, #73c367 100%);
        background: -webkit-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
        background: linear-gradient(to bottom, #7b9a76 0%, #60855b 66%, #4F7849 100%);
        border-bottom: 1px solid #444444;
      }
      table thead th {
        font-size: 15px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        border-left: 2px solid #D0E4F5;
      }
      table thead th:first-child {
        border-left: none;
      }
      
      table tfoot td {
        font-size: 14px;
      }
  }